@import "../../../styles/helpers";

.section {
    overflow: hidden; }

.wrapper {
    position: relative; }

.title {
    margin-bottom: 80px;
    @include d {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 32px;
        text-align: center; } }

.inner {
    margin: 0 -16px; }

.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: -8px -4px 16px; }

.preview {
    flex: 0 0 calc(33.333% - 8px);
    width: calc(33.333% - 8px);
    margin: 8px 4px 0;
    &:first-child {
        flex: 0 0 calc(100% - 8px);
        width: calc(100% - 8px);
        height: 400px; }
    img {
        width: 100%;
        border-radius: 8px;
        height: 100%; }
    video {
        width: 100%;
        border-radius: 8px;
        height: 100%; } }

.subtitle {
    margin-bottom: 8px;
    @include body-bold-1;
    color: $neutrals2;
    @include dark {
        color: $neutrals8; } }

.line {
    display: flex;
    align-items: center; }

.user {
    display: flex;
    align-items: center;
    margin-right: auto;
    color: $neutrals3;
    @include dark {
        color: $neutrals6; }
    span {
        font-weight: 500; } }

.avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }

.counter {
    flex-shrink: 0; }
